import { Modal } from '@/scripts/content/modal'
import { qsRequired } from '@/scripts/functions'
import { type DeferredMedia } from '@/scripts/media/deferred-media'

export class ProductModal extends Modal {
	static override htmlSelector = 'product-modal'
	constructor() {
		super()
	}

	override show(opener: HTMLButtonElement) {
		super.show(opener)
		this.showActiveMedia()
	}

	showActiveMedia() {
		if (!this.openedBy) throw `Can't find opener element, ${ProductModal}`
		this.querySelectorAll(
			`[data-media-id]:not([data-media-id="${this.openedBy.getAttribute('data-media-id')}"])`
		).forEach((element) => {
			element.removeAttribute('data-uc-active')
		})
		const activeMedia = qsRequired<HTMLImageElement | DeferredMedia>(
			`[data-media-id="${this.openedBy.getAttribute('data-media-id')}"]`,
			this
		)
		const activeMediaTemplate = activeMedia.querySelector('template')
		const activeMediaContent = activeMediaTemplate ? activeMediaTemplate.content : null
		activeMedia.setAttribute('data-uc-active', '')
		activeMedia.scrollIntoView()

		if ('width' in activeMedia) {
			const container = qsRequired('[role="document"]', this)
			container.scrollLeft = (activeMedia.width - container.clientWidth) / 2
		} else if (
			'loadContent' in activeMedia &&
			activeMediaContent &&
			activeMediaContent.querySelector('.js-youtube')
		) {
			activeMedia.loadContent()
		}
	}
}
