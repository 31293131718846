import { PUB_SUB_EVENTS, publish, subscribe, type uCoastWindow } from '@/scripts/setup'
import {
	closestOptional,
	closestRequired,
	getAttributeOrThrow,
	qsaOptional,
	qsaRequired,
	qsOptional,
	qsRequired,
	qsRequiredFromDocument,
	targetOptional,
} from '@/scripts/functions'
import { type ProductVariant, type ProductVariantOptionKeys } from '@/scripts/types/api'
import { type ShareButton } from '@/scripts/forms/share'
import { type ProductForm } from '@/scripts/product/product-form'
import { type MediaGallery } from '@/scripts/media/media-gallery'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { type QuickAddModal } from '@/scripts/product/quick-add'

declare let window: uCoastWindow

export class QuantityInput extends UcoastEl {
	static htmlSelector = 'quantity-input'
	input: HTMLInputElement
	changeEvent: Event
	quantityUpdateUnsubscriber?: () => void
	constructor() {
		super()
		this.input = qsRequired('input', this)
		this.changeEvent = new Event('change', { bubbles: true })

		this.input.addEventListener('change', this.onInputChange.bind(this))
		this.querySelectorAll('button').forEach((button) =>
			button.addEventListener('click', this.onButtonClick.bind(this))
		)
	}

	override connectedCallback() {
		super.connectedCallback()
		this.validateQtyRules()
		this.quantityUpdateUnsubscriber = subscribe(
			PUB_SUB_EVENTS.quantityUpdate,
			this.validateQtyRules.bind(this)
		)
	}

	override disconnectedCallback() {
		super.disconnectedCallback()
		if (this.quantityUpdateUnsubscriber) {
			this.quantityUpdateUnsubscriber()
		}
	}

	onInputChange() {
		this.validateQtyRules()
	}

	onButtonClick(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		const previousValue = this.input.value
		const target = targetOptional(event)
		target?.getAttribute('name') === 'plus' ? this.input.stepUp() : this.input.stepDown()
		if (previousValue !== this.input.value) this.input.dispatchEvent(this.changeEvent)
	}

	validateQtyRules() {
		const value = parseInt(this.input.value)
		if (this.input.min) {
			const min = parseInt(this.input.min)
			const buttonMinus = qsRequired(".quantity__button[name='minus']", this)
			buttonMinus.classList.toggle('disabled', value <= min)
		}
		if (this.input.max) {
			const max = parseInt(this.input.max)
			const buttonPlus = qsRequired(".quantity__button[name='plus']", this)
			buttonPlus.classList.toggle('disabled', value >= max)
		}
	}
}

export class VariantSelects extends UcoastEl {
	static htmlSelector = 'variant-selects'
	currentVariant: ProductVariant
	options: string[]
	variantData: ProductVariant[]
	optionsDict: ProductVariantOptionKeys[]

	constructor() {
		super()
		this.optionsDict = ['option1', 'option2', 'option3']
		this.variantData = this.getVariantData()
		this.options = this.updateOptions()
		this.currentVariant = this.updateMasterId()
		this.updateVariantStatuses()
		this.addEventListener('change', this.onVariantChange)
	}

	onVariantChange() {
		this.options = this.updateOptions()
		this.currentVariant = this.updateMasterId()
		this.removeErrorMessage()
		this.updateVariantStatuses()

		if (!this.currentVariant) {
			this.toggleAddButton('', '', '', window.variantStrings.soldOut)
			this.setUnavailable()
		} else {
			this.updateMedia()
			this.updateURL()
			this.updateVariantInput()
			this.renderProductInfo()
			this.updateShareUrl()
		}
	}

	updateOptions() {
		const options = Array.from(
			qsaRequired<HTMLSelectElement>('select', this),
			(select) => select.value
		)
		if (!options.length) throw 'options not found, updateOptions'
		return options
	}

	updateMasterId() {
		const currentVariant = this.variantData.find((variant) => {
			return !variant.options
				.map((option, index) => {
					if (!this.options.length) return false
					return this.options[index] === option
				})
				.includes(false)
		})
		if (!currentVariant) throw 'current variant not found, updateMasterId'
		return currentVariant
	}

	updateMedia() {
		const currentVariant = this.currentVariant
		if (!currentVariant) return
		if (!currentVariant.featured_media) return

		const mediaGalleries = qsaRequired<MediaGallery>(
			`[id^="MediaGallery-${this.dataset.section}"]`
		)
		mediaGalleries.forEach((mediaGallery) => {
			mediaGallery.setActiveMedia(
				`${this.dataset.section}-${currentVariant.featured_media.id}`,
				true
			)
		})

		const modalContent = qsOptional(
			`#ProductModal-${this.dataset.section} .product-media-modal__content`
		)
		if (!modalContent) return
		const newMediaModal = qsRequired(
			`[data-media-id="${currentVariant.featured_media.id}"]`,
			modalContent
		)
		modalContent.prepend(newMediaModal)
	}

	updateURL() {
		if (!this.currentVariant || this.dataset.updateUrl === 'false') return
		window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`)
	}

	updateShareUrl() {
		const shareButton = qsOptional<ShareButton>(`Share-${this.dataset.section}`)
		if (!shareButton || !shareButton.updateUrl || !this.currentVariant) return
		shareButton.updateUrl(
			`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`
		)
	}

	updateVariantInput() {
		const currentVariant = this.currentVariant
		if (!currentVariant) return
		const productForms = qsaOptional(
			`#product-form-${this.dataset.section}, #product-form-installment-${this.dataset.section}`
		)
		if (!productForms) return
		productForms.forEach((productForm) => {
			const input = qsRequired<HTMLInputElement>('input[name="id"]', productForm)
			input.value = `${currentVariant.id}`
			input.dispatchEvent(new Event('change', { bubbles: true }))
		})
	}

	updateVariantStatuses() {
		// identify any variants that are not available given the selected options
		const availableVariants = this.variantData.filter(
			(variant) =>
				variant.inventory_status === 'InStock' || variant.inventory_status === 'PreOrder'
		)
		console.log({ availableVariants })
		const selectedOptionsArr = Array.from(qsaRequired<HTMLInputElement>(':checked', this)).map(
			(el) => el.value
		)
		const selectedOptions = {
			option1: selectedOptionsArr[0],
			option2: selectedOptionsArr[1],
			option3: selectedOptionsArr[2],
		}
		const availableOptions = new Map<ProductVariantOptionKeys, (string | null)[]>()
		this.optionsDict.forEach((key) => {
			const optionVariants = availableVariants.filter((variant) => {
				const option1Match =
					!variant.option1 ||
					variant.option1 === selectedOptions.option1 ||
					key == 'option1'
				const option2Match =
					!variant.option2 ||
					variant.option2 === selectedOptions.option2 ||
					key == 'option2'
				const option3Match =
					!variant.option3 ||
					variant.option3 === selectedOptions.option3 ||
					key == 'option3'
				return option1Match && option2Match && option3Match
			})
			const options = optionVariants.map((variant) => variant[key])
			availableOptions.set(key, options)
		})

		const inputWrappers = qsaRequired('[data-uc-product-form-input]', this)
		inputWrappers.forEach((option, i) => {
			const key = this.optionsDict[i]
			const availableOptionInputsValue = availableOptions.get(key) ?? ['']
			const optionInputs = qsaRequired<HTMLInputElement>(
				'input[type="radio"], option',
				option
			)

			this.setInputAvailability(optionInputs, availableOptionInputsValue)
		})
	}

	setInputAvailability(
		listOfOptions: NodeListOf<HTMLInputElement>,
		listOfAvailableOptions: (string | null)[]
	) {
		listOfOptions.forEach((input: HTMLInputElement) => {
			const value = input.getAttribute('value')
			if (!value) throw `value missing on setInputvailability`
			if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
				input.innerText = value
			} else {
				input.innerText = window.variantStrings.unavailable_with_option.replace(
					'[value]',
					value
				)
			}
		})
	}

	removeErrorMessage() {
		const container =
			closestOptional(this, 'section') ??
			closestRequired<QuickAddModal>(this, 'quick-add-modal')
		const productForm = qsRequired<ProductForm>('product-form', container)
		productForm.handleErrorMessage()
	}

	renderProductInfo() {
		const currentVariant = this.currentVariant
		if (!currentVariant) throw 'no current variant'
		const requestedVariantId = currentVariant.id
		const sectionId = this.dataset.originalSection
			? this.dataset.originalSection
			: this.dataset.section

		fetch(
			`${this.dataset.url}?variant=${requestedVariantId}&section_id=${
				this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
			}`
		)
			.then((response) => response.text())
			.then((responseText) => {
				// prevent unnecessary ui changes from abandoned selections
				if (currentVariant.id !== requestedVariantId) return

				const html = new DOMParser().parseFromString(responseText, 'text/html')
				const priceDestinations = document.querySelectorAll(
					`[data-uc-price-selector="${this.dataset.section}"]`
				)
				const priceSource = html.getElementById(
					`price-${
						this.dataset.originalSection
							? this.dataset.originalSection
							: this.dataset.section
					}`
				)
				const skuSource = html.getElementById(
					`Sku-${
						this.dataset.originalSection
							? this.dataset.originalSection
							: this.dataset.section
					}`
				)
				const skuDestination = document.getElementById(`Sku-${this.dataset.section}`)
				const inventorySource = html.getElementById(
					`Inventory-${
						this.dataset.originalSection
							? this.dataset.originalSection
							: this.dataset.section
					}`
				)
				const inventoryDestination = document.getElementById(
					`Inventory-${this.dataset.section}`
				)

				if (priceSource && priceDestinations) {
					console.log('update full destination')
					priceDestinations.forEach((destination) => {
						destination.innerHTML = priceSource.innerHTML
					})
				}
				if (inventorySource && inventoryDestination) {
					console.log('update inventory destination')
					inventoryDestination.innerHTML = inventorySource.innerHTML
				}
				if (skuSource && skuDestination) {
					skuDestination.innerHTML = skuSource.innerHTML
					skuDestination.classList.toggle(
						'visibility-hidden',
						skuSource.classList.contains('visibility-hidden')
					)
				}

				const price = document.getElementById(`price-${this.dataset.section}`)

				if (price) price.classList.remove('visibility-hidden')

				if (inventoryDestination && inventorySource)
					inventoryDestination.classList.toggle(
						'visibility-hidden',
						inventorySource.innerText === ''
					)

				const addButtonUpdated = qsRequiredFromDocument<HTMLButtonElement>(
					`#ProductSubmitButton-${sectionId}`,
					html
				)
				const productTitle = getAttributeOrThrow('data-uc-product-title', addButtonUpdated)
				const variantId = getAttributeOrThrow('data-uc-variant-id', addButtonUpdated)
				const inventoryStatus = getAttributeOrThrow(
					'data-uc-inventory-status',
					addButtonUpdated
				)
				const inventoryStatusText = getAttributeOrThrow(
					'data-uc-inventory-status-text',
					addButtonUpdated
				)
				this.toggleAddButton(productTitle, variantId, inventoryStatus, inventoryStatusText)

				publish(PUB_SUB_EVENTS.variantChange, {
					data: {
						sectionId,
						html,
						variant: this.currentVariant,
					},
				})
			})
	}

	toggleAddButton(
		productTitle: string,
		variantId: string,
		inventoryStatus: string,
		inventoryStatusText: string
	) {
		const productForm = qsOptional<ProductForm>(`#product-form-${this.dataset.section}`)
		if (!productForm) return
		const addButton = qsOptional('[name="add"]', productForm)
		const addButtonText = qsOptional('[name="add"] > span', productForm)
		if (!addButton || !addButtonText) return
		addButton.setAttribute('data-uc-product-title', productTitle)
		addButton.setAttribute('data-uc-variant-id', variantId)
		addButton.setAttribute('data-uc-inventory-status', inventoryStatus)
		addButtonText.innerHTML = inventoryStatusText
	}

	setUnavailable() {
		const button = qsRequired(`#product-form-${this.dataset.section}`)
		const addButton = qsRequired('[name="add"]', button)
		const addButtonText = qsRequired('[name="add"] > span', button)
		const price = qsRequired(`#price-${this.dataset.section}`)
		const inventory = qsRequired(`#Inventory-${this.dataset.section}`)
		const sku = qsRequired(`#Sku-${this.dataset.section}`)

		if (!addButton) return
		addButtonText.textContent = window.variantStrings.unavailable
		if (price) price.classList.add('visibility-hidden')
		if (inventory) inventory.classList.add('visibility-hidden')
		if (sku) sku.classList.add('visibility-hidden')
	}

	getVariantData() {
		const textContent = qsRequired('[type="application/json"]', this).textContent
		if (!textContent) throw `textContent not found in getVariantData`
		const variantData = JSON.parse(textContent)
		if (!variantData) throw 'variant data undefined'
		return variantData
	}
}
