import { addToCartConfig, PUB_SUB_EVENTS, publish, uCoastWindow } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { routes } from '@/scripts/setup'
import {
	closestOptional,
	getAttributeOrThrow,
	qsaOptional,
	qsOptional,
	qsRequired,
	toggleLoading,
} from '@/scripts/functions'
import { type QuickAddModal } from '@/scripts/product/quick-add'
import { type NotifyMe } from '@/scripts/content/modal'

// @ts-ignore
//import { ucoastSwym } from '@/scripts/swym'

declare let window: uCoastWindow

export class ProductForm extends UcoastEl {
	static htmlSelector = 'product-form'
	form: HTMLFormElement
	submitButton: HTMLButtonElement
	//wishlistButton: HTMLAnchorElement
	wishlistListId?: string
	spinner: HTMLElement
	errorMessageWrapper?: HTMLElement
	errorMessage?: HTMLElement
	notifyMe?: NotifyMe
	error: boolean

	constructor() {
		super()
		this.form = this.initForm()
		this.submitButton = qsRequired('[type="submit"]', this)
		//this.wishlistButton = qsRequired('[data-uc-add-to-wishlist]', this)
		this.spinner = qsRequired('[data-uc-spinner]', this)
		this.error = false
		this.errorMessageWrapper = qsOptional('[data-uc-error-message-wrapper]', this)
		if (!this.errorMessageWrapper) return
		this.errorMessage = qsRequired('[data-uc-error-message]', this.errorMessageWrapper)
		this.submitButton.addEventListener('click', (event: Event) => {
			//window.suppressWishlistAdd = true // this is set to false on rebuy success
			const inventoryStatus = getAttributeOrThrow(
				'data-uc-inventory-status',
				this.submitButton
			)
			if (inventoryStatus === 'OutOfStock') {
				event.preventDefault()
				this.notifyMe = qsRequired<NotifyMe>('notify-me')
				this.notifyMe.initialize(this.submitButton)
			}
			// hack and workaround to submit the form if we are in quick add and product options are initialized
			// dont be mad at me, be mad at your boss for not approving budget for a proper solution
			const optionsLoaded = qsOptional('[id^="hulkapps_custom_options"]')
			const quickAddOpenModal = qsOptional('quick-add-modal[open]')
			if (optionsLoaded && quickAddOpenModal) {
				console.log('reinit rebuy')
				//window.suppressWishlistAdd = true // yes this is necessary
				this.submitButton.setAttribute('disabled', '') // also, yes this is necessary
				this.onSubmitHandler()
			}
		})
		/*this.wishlistButton.addEventListener('click', (event: Event) => {
			event.preventDefault()
			this.submitButton.setAttribute('disabled', '')
			const variantId = getAttributeOrThrow('data-uc-variant-id', this.submitButton)
			const productId = getAttributeOrThrow(
				'data-uc-wishlist-product-id',
				this.wishlistButton
			)
			const productUrl = getAttributeOrThrow(
				'data-uc-wishlist-product-url',
				this.wishlistButton
			)
			if (!window.suppressWishlistAdd) {
				// product options triggers wishlist add
				this.addToWishlist(parseInt(variantId), parseInt(productId), productUrl)
			}
		})*/
		this.form.addEventListener('submit', function (event) {
			event.preventDefault()
		})
		//ucoastSwym()
	}

	/*addToWishlist(variantId: number, productId: number, productUrl: string) {
		if (window.addToWishlist && qsRequired('body').hasAttribute('data-uc-wishlist-list-id')) {
			window.addToWishlist(
				{
					epi: variantId,
					empi: productId,
					du: productUrl,
				},
				getAttributeOrThrow('data-uc-wishlist-list-id', qsRequired('body'))
			)
			this.submitButton.removeAttribute('disabled')
		} else {
			window.setTimeout(() => {
				// dumb but necessary
				if (
					window.addToWishlist &&
					qsRequired('body').hasAttribute('data-uc-wishlist-list-id')
				) {
					window.addToWishlist(
						{
							epi: variantId,
							empi: productId,
							du: productUrl,
						},
						getAttributeOrThrow('data-uc-wishlist-list-id', qsRequired('body'))
					)
					this.submitButton.removeAttribute('disabled')
				} else {
					console.error('addToWishlist is not defined')
				}
			}, 4000)
		}
	}*/

	initForm(): HTMLFormElement {
		const form = qsRequired<HTMLFormElement>('form', this)
		const id = qsRequired<HTMLInputElement>('[name=id]', form)
		id.disabled = false
		form.addEventListener('submit', this.onSubmitHandler.bind(this))
		return form
	}

	onSubmitHandler(evt?: SubmitEvent): void {
		console.log('form onSubmit')
		if (evt) evt.preventDefault()
		if (window.supressAddToCart) return
		window.supressAddToCart = true
		//window.suppressWishlistAdd = true // this is set to false on rebuy success
		if (this.submitButton.getAttribute('aria-disabled') === 'true') {
			//window.suppressWishlistAdd = false
			return
		}

		const requiredElements = qsaOptional<HTMLInputElement | HTMLSelectElement>(
			'input[required], select[required]',
			this.form
		)
		console.log({ requiredElements })
		if (requiredElements) {
			for (let i = 0; i < requiredElements.length; i++) {
				const element = requiredElements[i]
				if (!element.value || element.value == '') {
					console.log('required not found')
					const parentWrapper = closestOptional(element, '.hulkapps_option')
					if (parentWrapper) {
						parentWrapper.classList.add('validation_error')
					}
					//window.suppressWishlistAdd = false
					this.submitButton.removeAttribute('aria-disabled')
					this.submitButton.removeAttribute('disabled')
					return
				} else {
					const parentWrapper = closestOptional(element, '.hulkapps_option')
					if (parentWrapper) {
						parentWrapper.classList.remove('validation_error')
					}
					this.submitButton.removeAttribute('aria-disabled')
					this.submitButton.removeAttribute('disabled')
				}
			}
		}

		this.handleErrorMessage()

		this.submitButton.setAttribute('aria-disabled', '')
		toggleLoading(this.submitButton, true)
		this.spinner.classList.remove('hidden')

		const formData = new FormData(this.form)

		const config = addToCartConfig(formData)

		fetch(`${routes.cart_add_url}`, config)
			.then((response) => {
				return response
			})
			.then((response) => response.json())
			.then((response) => {
				if (response.status) {
					this.handleErrorMessage(response.description)

					const soldOutMessage = this.submitButton.querySelector('.sold-out-message')
					if (!soldOutMessage) return
					this.submitButton.setAttribute('aria-disabled', '')
					const text = this.submitButton.querySelector('span')
					if (text) text.classList.add('hidden')
					soldOutMessage.classList.remove('hidden')
					this.error = true
					return
				} else {
					console.log({ response })
					console.log('Cart add passed to rebuy')
					window.Rebuy.SmartCart.updateLiveRegion()
					window.Rebuy.SmartCart.show()
					return
				}

				if (!this.error) publish(PUB_SUB_EVENTS.cartUpdate, { source: 'product-form' })
				this.error = false
				const quickAddModal = closestOptional<QuickAddModal>(this, 'quick-add-modal')
				if (quickAddModal) {
					quickAddModal.hide(true)
					document.body.addEventListener(
						'modalClosed',
						() => {
							setTimeout(() => {
								// TODO: trigger rebuy cart reload
							})
						},
						{ once: true }
					)
					quickAddModal.hide(true)
				} else {
					// TODO: trigger rebuy cart reload
				}
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				toggleLoading(this.submitButton, false)
				if (!this.error) this.submitButton.removeAttribute('aria-disabled')
				this.spinner.classList.add('hidden')
			})
	}

	handleErrorMessage(errorMessage: string | false = false): void {
		this.errorMessageWrapper =
			this.errorMessageWrapper || qsOptional('[data-uc-error-message-wrapper]', this)
		if (!this.errorMessageWrapper) return
		this.errorMessage =
			this.errorMessage || qsOptional('[data-uc-error-message]', this.errorMessageWrapper)
		this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage)
		if (errorMessage && this.errorMessage) {
			this.errorMessage.textContent = errorMessage
		}
	}
}
