import { qsaOptional, qsOptional } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { replaceSrcSet } from '@/scripts/mediaLoader'

export class ProductRecommendations extends UcoastEl {
	static htmlSelector = 'product-recommendations'
	constructor() {
		super()
	}

	override connectedCallback() {
		super.connectedCallback()
		const url = this.dataset.url
		if (!url) throw `url not found, ProductRecommendations`

		fetch(url)
			.then((response) => response.text())
			.then((text) => {
				const html = document.createElement('div')
				html.innerHTML = text
				const recommendations = qsOptional('product-recommendations', html)

				if (recommendations instanceof ProductRecommendations) {
					this.innerHTML = recommendations.innerHTML
					const imagesToPreload = qsaOptional<HTMLImageElement>('img[data-src]', this)
					imagesToPreload?.forEach((image) => {
						replaceSrcSet(image)
					})
				} else {
					console.error(
						'returned something unexpected from product recommendations',
						recommendations
					)
				}

				if (
					!this.querySelector('slideshow-component') &&
					this.classList.contains('complementary-products')
				) {
					this.remove()
				}

				if (html.querySelector('.grid__item')) {
					this.classList.add('product-recommendations--loaded')
				}
			})
			.catch((e) => {
				console.error(e)
			})
	}
}
