import { Modal } from '@/scripts/content/modal'
import {
	elementPropertyRequired,
	qsaOptional,
	qsOptional,
	qsRequired,
	qsRequiredFromDocument,
	replaceAll,
	toggleLoading,
} from '@/scripts/functions'
import { type uCoastWindow } from '@/scripts/setup'
import { VariantSelects } from '@/scripts/product/product-options'
import { ProductInfo } from '@/scripts/product/product-info'

declare let window: uCoastWindow

export class QuickAddModal extends Modal {
	static override htmlSelector = 'quick-add-modal'
	modalContent: HTMLElement
	productElement?: HTMLElement
	startingMediaWidth = 1364 // if this is changed in main-product.liquid, we need to change it here too

	constructor() {
		super()
		this.modalContent = qsRequired('[id^="QuickAddInfo-"]', this)
	}

	calculateQuickAddWidth() {
		return window.matchMedia('(min-width: 990px)').matches
			? 1000
			: (window.innerWidth * 2).toFixed(0)
	}

	override hide(preventFocus = false) {
		this.modalContent.innerHTML = ''

		if (preventFocus) this.openedBy = undefined
		super.hide()
		//window.is_hulk_load_js = false // the script checks for this property before running
		//window.is_hulk_required_options = undefined
	}

	override show(opener: HTMLButtonElement) {
		opener.setAttribute('aria-disabled', 'true')
		toggleLoading(opener, true)
		const spinner = qsRequired('[data-uc-spinner]', opener)
		spinner.classList.remove('hidden')
		const productUrl = opener.getAttribute('data-product-url') as string | undefined
		if (!productUrl) throw `Product Url Undefined, QuickAddModal`

		fetch(productUrl)
			.then((response) => response.text())
			.then((responseText) => {
				const responseHTML = new DOMParser().parseFromString(responseText, 'text/html')
				this.productElement = qsRequiredFromDocument(
					'section[id^="MainProduct-"]',
					responseHTML
				)
				this.preventDuplicatedIDs()
				this.removeDOMElements()
				this.setInnerHTML(this.modalContent, this.productElement.innerHTML)

				if (window.Shopify && window.Shopify.PaymentButton) {
					window.Shopify.PaymentButton.init()
				}

				this.removeGalleryListSemantic()
				this.preventVariantURLSwitching()
				super.show(opener)
			})
			.finally(() => {
				opener.removeAttribute('aria-disabled')
				toggleLoading(opener, false)
				spinner.classList.add('hidden')
				window.hulk_add_to_cart_ele = null
				//const hulkScript = qsRequired<HTMLScriptElement>('script[src*="hulkcode.js"]')
				if (window.is_hulk_load_js) {
					// reinitialize hulk script
					window.hulkapps.page_type = 'product'
					window.loadHulkManualOverride()
				}
			})
	}

	setInnerHTML(element: HTMLElement, html: string) {
		// replace image with correct size
		const quickAddWidth = this.calculateQuickAddWidth()
		// @ts-ignore
		let newHtml = html.replaceAll(this.startingMediaWidth.toString(), quickAddWidth.toString())

		// replace variant selects with different style because "its in the figma" lol
		newHtml = newHtml.replaceAll('disclosure--product', 'disclosure--center')
		newHtml = newHtml.replaceAll(
			'disclosure__list-wrapper--below',
			'disclosure__list-wrapper--center'
		)

		element.innerHTML = newHtml

		// Reinjects the script tags to allow execution. By default, scripts are disabled when using element.innerHTML.
		element.querySelectorAll('script').forEach((oldScriptTag: HTMLScriptElement) => {
			try {
				const scriptParent = elementPropertyRequired(oldScriptTag, 'parentNode')
				const newScriptTag = document.createElement('script')
				Array.from(oldScriptTag.attributes).forEach((attribute) => {
					newScriptTag.setAttribute(attribute.name, attribute.value)
				})
				newScriptTag.appendChild(document.createTextNode(oldScriptTag.innerHTML))

				scriptParent.replaceChild(newScriptTag, oldScriptTag)
			} catch (error) {
				console.log('Error replacing script tags into innerHTML')
			}
		})
		const hulkAppsCss = element.querySelector('link[type="text/css"][href*="hulkcode"]')
		if (hulkAppsCss) {
			hulkAppsCss.remove()
		}
	}

	preventVariantURLSwitching() {
		const variantPicker = this.modalContent.querySelector('variant-radios,variant-selects')
		if (!variantPicker) return

		variantPicker.setAttribute('data-update-url', 'false')
	}

	removeDOMElements() {
		if (!this.productElement) throw 'this.productElement not found'

		const productModal = this.productElement.querySelector('product-modal')
		if (productModal) productModal.remove()

		const modalDialog = this.productElement.querySelectorAll('modal-dialog')
		if (modalDialog) modalDialog.forEach((modal) => modal.remove())
	}

	preventDuplicatedIDs() {
		if (!this.productElement) throw 'this.productElement not found'
		const sectionId = this.productElement.dataset.section
		if (!sectionId) throw 'sectionId not found'
		this.productElement.innerHTML = replaceAll(
			this.productElement.innerHTML,
			sectionId,
			`quickadd-${sectionId}`
		)
		const optionEls = qsaOptional<VariantSelects | ProductInfo>(
			'variant-selects, variant-radios, product-info',
			this.productElement
		)
		if (!optionEls) return
		optionEls.forEach((element) => {
			element.dataset.originalSection = sectionId
		})
	}

	removeGalleryListSemantic() {
		const galleryList = qsOptional('[id^="Slider-Gallery"]', this.modalContent)
		if (!galleryList) return
		galleryList.setAttribute('role', 'presentation')
		galleryList
			.querySelectorAll('[id^="Slide-"]')
			.forEach((li) => li.setAttribute('role', 'presentation'))
	}
}
