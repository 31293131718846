import { PUB_SUB_EVENTS, publish, subscribe, VariantChangeEvent } from '@/scripts/setup'
import { qsOptional, qsRequired, scrollToAnchor } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { type VariantSelects } from '@/scripts/product/product-options'

export class ProductInfo extends UcoastEl {
	static htmlSelector = 'product-info'
	input: HTMLInputElement
	currentVariant: HTMLInputElement
	variantSelects: VariantSelects
	submitButton: HTMLButtonElement
	quantityForm?: HTMLElement
	reviewsButton?: HTMLAnchorElement
	cartUpdateUnsubscriber?: () => void
	variantChangeUnsubscriber?: () => void
	constructor() {
		super()
		this.input = qsRequired('[data-uc-quantity-input]', this)
		this.currentVariant = qsRequired('[data-uc-product-variant-id]', this)
		this.variantSelects =
			qsOptional('variant-selects', this) ?? qsRequired('variant-radios', this)
		this.submitButton = qsRequired('[type="submit"]', this)
		this.reviewsButton = qsOptional('[data-uc-go-to-reviews]', this)
	}

	override connectedCallback() {
		super.connectedCallback()
		if (this.reviewsButton) {
			this.reviewsButton.addEventListener('click', (event) => {
				event.preventDefault()
				scrollToAnchor('[data-uc-reviews-scroll-anchor]')
			})
		}
		this.quantityForm = qsOptional('[data-uc-quantity-form]', this)
		if (!this.quantityForm) return
		this.setQuantityBoundries()
		if (!this.dataset.originalSection) {
			this.cartUpdateUnsubscriber = subscribe(
				PUB_SUB_EVENTS.cartUpdate,
				this.fetchQuantityRules.bind(this)
			)
		}
		this.variantChangeUnsubscriber = subscribe(
			PUB_SUB_EVENTS.variantChange,
			(event: VariantChangeEvent) => {
				const sectionId = this.dataset.originalSection
					? this.dataset.originalSection
					: this.dataset.section
				if (event.data.sectionId !== sectionId) return
				this.updateQuantityRules(event.data.sectionId, event.data.html)
				this.setQuantityBoundries()
			}
		)
	}

	override disconnectedCallback() {
		super.disconnectedCallback()
		if (this.cartUpdateUnsubscriber) {
			this.cartUpdateUnsubscriber()
		}
		if (this.variantChangeUnsubscriber) {
			this.variantChangeUnsubscriber()
		}
	}

	setQuantityBoundries() {
		const data = {
			cartQuantity: this.input.dataset.cartQuantity
				? parseInt(this.input.dataset.cartQuantity)
				: 0,
			min: this.input.dataset.min ? parseInt(this.input.dataset.min) : 1,
			max: this.input.dataset.max ? parseInt(this.input.dataset.max) : null,
			step: this.input.step ? parseInt(this.input.step) : 1,
		}

		let min = data.min
		const max = data.max === null ? data.max : data.max - data.cartQuantity
		if (max !== null) min = Math.min(min, max)
		if (data.cartQuantity >= data.min) min = Math.min(min, data.step)

		this.input.min = `${min}`
		this.input.max = `${max}`
		this.input.value = `${min}`
		publish(PUB_SUB_EVENTS.quantityUpdate, undefined)
	}

	fetchQuantityRules() {
		if (!this.currentVariant.value) return
		const overlay = qsOptional('.quantity__rules-cart [data-uc-loading-overlay]', this)
		if (overlay) overlay.classList.remove('hidden')
		fetch(
			`${this.dataset.url}?variant=${this.currentVariant.value}&section_id=${this.dataset.section}`
		)
			.then((response) => {
				return response.text()
			})
			.then((responseText) => {
				const html = new DOMParser().parseFromString(responseText, 'text/html')
				if (!this.dataset.section) return
				this.updateQuantityRules(this.dataset.section, html)
				this.setQuantityBoundries()
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				if (overlay) overlay.classList.add('hidden')
			})
	}

	updateQuantityRules(sectionId: string, html: Document) {
		const quantityFormUpdated = html.getElementById(`Quantity-Form-${sectionId}`)
		if (!quantityFormUpdated) throw `No quantity form found`
		const selectors = ['[data-uc-quantity-input]', '.quantity__rules', '.quantity__label']
		for (let selector of selectors) {
			if (!this.quantityForm) return
			const current = this.quantityForm.querySelector(selector)
			const updated = quantityFormUpdated.querySelector(selector)
			if (!current || !updated) continue
			if (selector === '[data-uc-quantity-input]') {
				const attributes = ['data-cart-quantity', 'data-min', 'data-max', 'step']
				for (let attribute of attributes) {
					const valueUpdated = updated.getAttribute(attribute)
					if (valueUpdated !== null) current.setAttribute(attribute, valueUpdated)
				}
			} else {
				current.innerHTML = updated.innerHTML
			}
		}
	}
}
